import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {iNodeItem} from '../../dataTypes/dataTypes';

const initialState: iNodeItem[] = [];

const searchSectionSlice = createSlice({
  name: 'searchSections',
  initialState,
  reducers: {
    storeSearchSections(state, action: PayloadAction<iNodeItem>) {
      return [...state.filter(item => item.abbrev.toLowerCase() !== action.payload.abbrev.toLowerCase()), ...[action.payload]]
    }, 
    deleteSearchSections(state, action: PayloadAction<string>) {
      return [...state.filter(item => item.abbrev.toLowerCase() !== action.payload.toLowerCase())]
    }, 
    clearSearchSections(state) {
      return [...initialState]
    } 
  }
})

export const {storeSearchSections, deleteSearchSections, clearSearchSections} = searchSectionSlice.actions;
export default searchSectionSlice.reducer;
export const searchSections = (state: RootState) => state.searchSections;

