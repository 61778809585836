import { iVersion } from "../../dataTypes/dataTypes";

const VersionReload = (props: iVersion) => {

    let messageDisplay = <></>;

    if (props.isLoading) {
        messageDisplay = <div>Getting application update...</div>;
    }

    if (props.isError) {
        messageDisplay =
            process.env.NODE_ENV !== 'production'
                ? <div>Error: {JSON.stringify(props.error)}</div>
                : <div style={{ margin: "1rem", fontSize: "1.2rem" }}>Sorry, trouble checking version.</div>;
    }        


    return messageDisplay;

};

export default VersionReload;