import {ReactNode} from 'react'
import {createPortal} from 'react-dom'

interface ChildProps {
  children?: ReactNode;
}

const Portal = ({children} : ChildProps) => {
  return createPortal(children, document.body)
}

export default Portal