import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import searchBookSliceReducer from '../features/search/searchBookSlice';
import searchSectionSliceReducer from '../features/search/searchSectionsSlice';
import searchResultSliceReducer from '../features/search/searchResultSlice';
import storedStarsSliceReducer, {storeStar, deleteStar, clearStars} from '../features/search/storedStarsSlice';
import purpleReducer from '../features/search/purpleSlice';
import {apiSlice} from '../features/api/apiSlice';
import {iStarItem} from '../dataTypes/dataTypes';
import { listenerMiddleware } from './listenerMiddleware'
// options:
//import { listenerMiddleware, startAppListening, addAppListener } from './listenerMiddleware'
//import type { AppStartListening } from './listenerMiddleware'
//const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: storeStar,
  effect: async (action, listenerApi) => { 
    const data: iStarItem[] = (listenerApi.getState() as RootState).storedStars;
    setLocalStorage(data);
}});

listenerMiddleware.startListening({
  actionCreator: deleteStar,
  effect: async (action, listenerApi) => { 
    const data: iStarItem[] = (listenerApi.getState() as RootState).storedStars;
    setLocalStorage(data);
}});

listenerMiddleware.startListening({
  actionCreator: clearStars,
  effect: async (action, listenerApi) => { 
    const data: iStarItem[] = (listenerApi.getState() as RootState).storedStars;
    setLocalStorage(data);
}});


const setLocalStorage = (starsStored: iStarItem[]) => {
  const stringStars = JSON.stringify(starsStored);
    localStorage.setItem('stars', stringStars);
}

export const store = configureStore({
  reducer: {
    searchBook: searchBookSliceReducer,
    searchSections: searchSectionSliceReducer,
    searchResult: searchResultSliceReducer,
    storedStars: storedStarsSliceReducer,
    purple: purpleReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
    },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware).prepend(listenerMiddleware.middleware);
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
