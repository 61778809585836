import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {iNodeItem} from '../../dataTypes/dataTypes';

const initialState = <iNodeItem | null>null;

const searchBookSlice = createSlice({
  name: 'searchBook',
  initialState,
  reducers: {
    storeSearchBook(state, action: PayloadAction <iNodeItem | null>) {
      return action.payload;
    } 
  }
})

export const {storeSearchBook} = searchBookSlice.actions;
export default searchBookSlice.reducer;
export const searchBook = (state: RootState) => state.searchBook;

