
import { useAppSelector } from "../../app/hooks";
import { iSearchItem } from "../../dataTypes/dataTypes";
import { useGetSearchQuery } from "../api/apiSlice";
import { searchSections } from "../search/searchSectionsSlice";
import { searchBook } from "../search/searchBookSlice";
import SearchResultList from "./SearchResultList";

const SearchResult = (props: { pid: number, searchString: string, showList: string }) => {
    //
    const sections = useAppSelector(searchSections);
    const book = useAppSelector(searchBook);
    //
    let textnode = '', words = '';
    if (sections.length > 0) {
        textnode = sections[sections.length - 1].hiernode;
    }

    words = props.searchString;

    /*
        to-do: build nodeSet string like '/2/,/3/'
        but low priority, e.g., results in 4 Gospels w/bubble to top
        but if just working on John's letters... to-do
    */

    /*
        m/have pid
        if: node, nodeSet, or no words.. m/have docid 
    */

    const {
        data = [] as iSearchItem[],
        isSuccess,
        isLoading,
        isError,
        error,
    } = useGetSearchQuery({
        pid: props.pid,
        docid: book ? book.docid : -1,
        textNode: textnode,
        nodeSet: '',
        searchWords: words
    });

    let searchContent = <></>;

    if (isSuccess) {
        searchContent = <SearchResultList data={data} showList={props.showList} />
    }

    if (isLoading) {
        searchContent = <div>Is loading...</div>;
    }

    if (isError) {
        searchContent =
            process.env.NODE_ENV !== 'production'
                ? <div>Error: {JSON.stringify(error)}</div>
                : <div style={{ margin: "1rem", fontSize: "1.2rem" }}>Sorry. I had trouble with this search.</div>;
    }

    return <>{searchContent}</>;
}

export default SearchResult;

