import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {iNodeItem, iSectionArgs, iSearchItem, iSearchArgs, iGetRefs, iGetRefsArgs, iGetFormDataArgs, iGetFormData, iData} 
  from '../../dataTypes/dataTypes';

//const testUrl = 'https://dd275e3cfaeb.ngrok.io/api';  // ngrok
//const testUrl = 'http://localhost:7071/api';  // vsc
const testUrl = 'http://localhost:7190/api';  // visual studio


const baseUrl = () => {
  let r = testUrl;
  if (process.env.NODE_ENV === 'production') {
    r = 'https://pkb111.azurewebsites.net/api'
  }
  return r;
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl()
  }),
  //endpoints: builder => ({})
  
  endpoints: builder => ({

    getBookList: builder.query<iNodeItem[], number>({
      query: (pid) => `/GetBookList?pid=${pid.toString()}`
    }),

    getRsvTop3Levels: builder.query<iNodeItem[], number>({
      query: (pid) => `/GetRsvTop3Levels?pid=${pid.toString()}`
    }),

    getSectionList: builder.query<iNodeItem[], iSectionArgs>({
      query: ( {pid, docid, textnode} ) => {
        return `/GetChildren?pid=${pid.toString()}&docid=${docid.toString()}&textnode=${textnode}`;
      }
    }),

    getFormData: builder.query<iGetFormData[], iGetFormDataArgs>({
      query: ( {pid, docid, fromAbbrev, toAbbrev, fromCid, toCid} ) => {
        return `/GetFormData?pid=${pid.toString()}&docid=${docid.toString()}&fromAbbrev=${fromAbbrev}&toAbbrev=${toAbbrev}&fromCid=${fromCid.toString()}&toCid=${toCid.toString()}`;
      }
    }),

    getRefs: builder.query<iGetRefs[], iGetRefsArgs>({
      query: ( {pid, fromCid, toCid} ) => {
        return `/GetRefs?pid=${pid.toString()}&fromCid=${fromCid.toString()}&toCid=${toCid.toString()}`;
      }
    }),

    getSearch: builder.query<iSearchItem[], iSearchArgs>({
      query: ( {pid, docid, textNode, nodeSet, searchWords} ) => {
        let s = searchWords.replaceAll("&", "-!!-");
        // remove smart left/right quotes, e.g.,"“Evil one”"
        s = s.replaceAll('“', '"');
        s = s.replaceAll('”', '"');
        return `/GetSearch?pid=${pid.toString()}&docid=${docid.toString()}&textnode=${textNode}&nodeSet=${nodeSet}&searchWords=${s}`;
      }
    }),

    getVersion: builder.query<iData[], null>({
      query: () => `/GetVersion`
    })
  

  })
  
})

export const { useGetBookListQuery, useGetRsvTop3LevelsQuery, useGetSectionListQuery, useGetSearchQuery, useGetRefsQuery, useGetFormDataQuery, useGetVersionQuery } = apiSlice;

