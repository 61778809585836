import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {iSearchItem} from '../../dataTypes/dataTypes';

type iSearchResultList = iSearchItem[]

const initialState = new Array<iSearchItem>()

const searchResultSlice = createSlice({
  name: 'searchResult',
  initialState,
  reducers: {
    storeSearchResult(state, action: PayloadAction<iSearchResultList>) {
      return action.payload;
    } 
  }
})

export const {storeSearchResult} = searchResultSlice.actions;
export default searchResultSlice.reducer;
export const searchResult = (state: RootState) => state.searchResult;
