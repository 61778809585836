import { useAppSelector } from "../../app/hooks";
import { searchBook } from "../search/searchBookSlice";
import GetFormData from "./GetFormData";

const RangeSearch = (props: {pid: number, searchString: string, showList: string}) => {
    //
    const book = useAppSelector(searchBook);
    //
    let words = '', fromAbbrev = '', toAbbrev = '';
    words = props.searchString;

    // expecting abbrev to left of dots and maybe to right
    const dots = words.indexOf(" ..");

    if (dots > 0) {
        fromAbbrev = words.substring(0, dots).trim();
        if (words.length > dots + 3) {
            toAbbrev = words.substring(dots + 3).trim();
        } else {
            toAbbrev = fromAbbrev;
        }
    }

    let docid: string = book ? book.docid.toString() : "";

    return (
        docid.length > 0 ? 
        <GetFormData
            pid={props.pid}
            docid= {book ? book.docid : 0}
            fromAbbrev = {fromAbbrev}
            toAbbrev = {toAbbrev}
            fromCid= {0}
            toCid = {0}
        ></GetFormData> :
        <></>
    )    
}

export default RangeSearch;

