import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { Image } from 'react-bootstrap'
import { Star } from 'react-bootstrap-icons';
import youtube from '../../images/youtube_icon.png'

const Help = () => {

    //
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.initialize('G-BTJTDNCFZX');
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        }
    }, []);
    //


    return (
        <div className="text-list-container" style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            <h2 style={{ color: "#6495ED", marginBottom: "1rem" }}>Instructions</h2>
            <p>
                The Bible (rsv) and Catechism (ccc) are cross-referenced. There are two ways to retrieve Bible verses and
                Catechism paragraphs. The first is to "search" for content. The other is to specify a "range",
                e.g., a range of verses from a reading at today's Mass. To
                watch a quick tutorial, see <a href="https://youtu.be/2huAPCSXpWk"
                    target="_blank" rel="noopener noreferrer"
                    style={{ fontStyle: "italic" }}
                >
                    <Image src={youtube}
                        className="img-fluid rounded"
                        title="RSV x CCC"
                        width="32px"
                        style={{ marginRight: "0.5rem" }}
                    />
                    (5 minutes)
                </a>
            </p>

            <h3>Search</h3>
            <p>Search criteria are entered in two parts. The first, which is optional, is context using abbreviations,
                e.g., "rsv" or "ccc".
                rsv can be followed by "ot" or "nt". nt might be followed by a book, e.g., "jn" for the Gospel of John.
                Each abbreviation must be followed by a space. To see abbreviations used, select items from search
                menu.
            </p>
            <p>
                The second part is entering the words to find. After the context abbreviation, enter search words. A 
                space between words indicates "or". An ampersand indicates "and", e.g., devil & satan
            </p>
            <p>
                Search uses the inflectional form of the word. For an exact match, enclose characters with quotes,
                for instance, "evil one".
            </p>
            <p>
                You can also place an exclamation mark before a word or parentheses to exclude it. For example,
                the above search but verses not containing kingdom --
                "evil one" & !kingdom
            </p>
            <p>
                Please note, parentheses can also be used to form more complex searches.
            </p>
            <p>
                Also, to search both books, do not enter context and just type word criteria.
            </p>

            <h3>Range</h3>
            <p>
                You can retrieve a range of Bible verses, or just one verse or Catechism paragraph. A range uses two dots, ".."
                For example, specify the book (rsv or ccc), and the range. Notice the use of two dots. Colons are not used.
            </p>
            <p>rsv&nbsp;jn.6.37 .. jn.6.40</p>
            <p>ccc&nbsp;1860 ..</p>

            <h3 className="d-flex align-items-center">Stars &nbsp; <Star /></h3>
            <p>
                After clicking on a CCC paragraph or Bible verse or range to view their cross-references,
                notice a black star in the middle of the beige border. You can click this star to put it in a group
                for later consideration. To view starred items, click the big yellow star to the right of
                search button. These stars will be remembered until you click the [Clear] button.
            </p>
        </div>
    )
}

export default Help;