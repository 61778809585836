import { useState, useEffect } from "react";
import { iSearchItem } from "../../dataTypes/dataTypes";
import Button from "react-bootstrap/Button";
import "./search.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { storePurple, storedPurple } from "../search/purpleSlice";
import GetFormData from "./GetFormData";

interface iResultItems {
    data: iSearchItem[],
    handleResultItemClick: (i: iSearchItem) => void,
    purple: string[]
}

interface iResultItem {
    data: iSearchItem,
    handleResultItemClick: (i: iSearchItem) => void,
    purple: string[]
}

const SearchRow = ({ data, handleResultItemClick, purple }: iResultItem) => {
    return (
        <div>
            <div className="d-flex align-items-center">
                <span style={{ fontSize: "1.2rem" }}>{data.docabbrev}</span>
                &nbsp;&nbsp;
                <Button
                    key={data.cid.toString()}
                    variant="link"
                    style={{ fontSize: "1.2rem", textDecoration: "none", color: purple.find( s => s === data.abbrev) ? "purple" : "blue" }}
                    onClick={() => handleResultItemClick(data)}
                >
                    {data.abbrev.length > 0 ? data.abbrev : 'click' }
                </Button>
            </div>
            <div>
                {data.textcontent}
            </div>
        </div>
    )
}

const DisplayRows = ({ data, handleResultItemClick, purple }: iResultItems) => {
    return (
        <div className="text-list-container" style={{marginBottom: "5rem"}}>
            <div>Found: {data.length === 100 ? '100+' : data.length}</div>
            <div>
                {
                    data.map((item: iSearchItem) => (
                        <SearchRow key={item.cid.toString()} data={item} handleResultItemClick={handleResultItemClick} purple={purple} />
                    ))
                }

            </div>
        </div>
    )
}

const SearchResultList = (props: { data: iSearchItem[], showList: string }) => {
    const [item, setItem] = useState<iSearchItem | null>(null);
    const purple = useAppSelector(storedPurple);
    const dispatch = useAppDispatch();

    useEffect( () => {
        setItem(null);
    }, [props.showList])

    const backToSearchClick = () => {
        setItem(null);
    }

    const handleResultItemClick = (item: iSearchItem) => {
        setItem(item);
        dispatch(storePurple(item.abbrev));
    }

    let resultContent = <></>;
    if (item) {
        resultContent = <GetFormData 
            pid = {item.pid}
            docid = {item.docid}
            fromAbbrev = {""}
            toAbbrev = {""}
            fromCid = {item.cid}
            toCid = {item.cid}        
            backToSearchClick = {backToSearchClick}
            heading = {item.abbrev}
            listType={"search"}
            />;
    } else {
        resultContent = <DisplayRows data={props.data} handleResultItemClick={handleResultItemClick} purple={purple} />;
    }

    return <>{resultContent}</>;

}


export default SearchResultList;

