import React, { useState, useEffect, useRef } from "react";
import ReactGA from 'react-ga4';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./search.scss";
import ListPortal from "../portal/ListPortal";
import { iPtypes, iNodeItem } from "../../dataTypes/dataTypes";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { searchBook, storeSearchBook } from "./searchBookSlice";
import {
  searchSections,
  storeSearchSections,
  deleteSearchSections,
  clearSearchSections
} from "../search/searchSectionsSlice";
import SearchResult from "./SearchResult";
import RangeSearch from "./RangeSearch";
import { Star, StarFill } from 'react-bootstrap-icons';
import { storedStars, loadStars } from "../search/storedStarsSlice";
import StarsList from "./StarsList";
import Tips from "./Tips";
import VersionReload from "./VersionReload";
import { useVersion, useBookList, useSectionList } from "./searchHelper";

const Search = () => {
  //
  const book = useAppSelector(searchBook);
  const sections = useAppSelector(searchSections);
  const starsStored = useAppSelector(storedStars);
  const dispatch = useAppDispatch();
  // assuming we search one book or all books
  const [search, setSearch] = useState("");
  const [words, setWords] = useState("");
  const [twoDots, setTwoDots] = useState(false);
  const [showList, setShowList] = useState("");
  const [showPortal, setShowPortal] = useState<boolean>(false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [showStars, setShowStars] = useState<boolean>(false);
  const [showTips, setShowTips] = useState(true);
  //const [blurPosition, setBlurPosition] = useState<number | null>(null);
  const [inputPosition, setInputPosition] = useState<number | null>(null);


  // referenceElement is used to set focus on search field
  //const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
  //  null
  //);
  const referenceElement = useRef<HTMLInputElement>(null);
  // using custom hooks:
  const version = useVersion();
  const bookList = useBookList(1);
  const sectionList = useSectionList(1, book, sections);
  //
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-BTJTDNCFZX');
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);
  //
  //
  /*
  useEffect(() => {
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    }
  }, []);
  //
  const handleWindowFocus = () => {
    console.log("window FOCUS")
  }
  const handleWindowBlur = () => {
    console.log("window BLUR")    
  }
  */
  //
  //
  useEffect(() => {
    const getdata = localStorage.getItem("stars");
    if (getdata) {
      const x = JSON.parse(getdata);
      dispatch(loadStars(x));
    }
  }, [dispatch])

  useEffect(() => {
    referenceElement.current?.setSelectionRange(inputPosition, inputPosition);
  }, [inputPosition])

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    //setBlurPosition(e.target.selectionStart);
    let classLength = 0;
    if (book) {
      classLength = book.abbrev.length + 1;
      sections.forEach(sect => {
        classLength = classLength + sect.abbrev.length + 1;
      });
    }
  }

  const calcSeachWords = (s: string): string => {
    // TO-DO? PROBLEM IF SEARCHING FOR MULTIPLE SPACES
    s.replaceAll("     ", " ")
      .replaceAll("    ", " ")
      .replaceAll("   ", " ")
      .replaceAll("  ", " ");
    let sw = "", bs = "";
    bs = book ? book.abbrev + " " : "";
    if (bs.length > 0) {
      sections.forEach(sect => {
        bs = bs + sect.abbrev + " "
      })
      if (s.startsWith(bs)) {
        sw = s.substring(bs.length).trim();
      }
    } else {
      sw = s.trim();
    }
    return sw;
  }

  // submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    /* 
        CALC WORDS:
        Remove book/section(s) from search string 
    */
    const sw = calcSeachWords(search);
    setWords(sw);
    //
    setShowTips(false);
    setShowStars(false);
    setShowList(Math.random().toString());
    setShowPortal(false);
    setShowSearchResults(true);
  };

  // book/section click:
  const handleItemClick = (n: iNodeItem) => {
    // fyi - setSearch() does not trigger onChange, i.e., handleOnChange
    let s = search;
    //
    let newValue = "";
    let ipos = 0;
    // dispatch book or section, and setSearch
    if (!book) {
      // book
      newValue = n.abbrev + ' ' + s.trim();
      ipos = n.abbrev.length + 1;
      dispatch(storeSearchBook(n));
    } else {
      // section
      let nowContext = book.abbrev + ' ';
      sections.forEach(sect => {
        nowContext = nowContext + sect.abbrev + ' '
      })
      newValue = nowContext + n.abbrev + ' ' + s.replace(nowContext, '').trimStart();
      ipos = (nowContext + n.abbrev + ' ').length;
      dispatch(storeSearchSections(n));
    }
    //
    setSearch(newValue);
    setInputPosition(ipos);
    //
    if (referenceElement.current) {
      referenceElement.current.focus();
    }
  };


  const handleClickStar = () => {
    setShowTips(false);
    setShowPortal(false);
    setShowSearchResults(false);
    setShowList(Math.random().toString())
    setShowStars(true);
  }

  const clearSearch = () => {
    dispatch(clearSearchSections());
    dispatch(storeSearchBook(null));
    setSearch("");
    setWords("");
    setTwoDots(false);
    setShowTips(false);
    setShowStars(false);
    setShowSearchResults(false);
    setShowPortal(true);
    if (referenceElement.current) {
      referenceElement.current.focus();
    }
  }

  const displayTips = () => {
    setShowPortal(false);
    setShowSearchResults(false);
    setShowStars(false);
    setShowTips(true);
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    //
    setShowPortal(true);
    setShowTips(false);
    setShowStars(false);
    setShowSearchResults(false);
  };

  const makeRemoveString = (aBookSections: string[], pos: number) => {
    let r: string[] = [];
    let bk = pos === 0 ? true : false;
    for (let i = pos + 1; i < aBookSections.length; i++) {
      if (!bk) {
        dispatch(deleteSearchSections(aBookSections[i]));
      }
      r.push(aBookSections[i] + ' ');
    }
    return r;
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //
    setShowStars(false);
    setShowSearchResults(false);
    setShowTips(false);
    //
    const v = e.target.value;

    if (v.trim().length > 0) {
      //
      let s = v;
      //
      let removeStrings: string[] = []; // if editing middle
      // 
      let ss = s.replaceAll("     ", " ")
        .replaceAll("    ", " ")
        .replaceAll("   ", " ")
        .replaceAll("  ", " ");
      //
      let aSearchWords: string[] = ss.toLowerCase().trim().split(' ').filter(i => i.length > 0);
      let aBookSections: string[] = [];
      if (book) {
        aBookSections.push(book.abbrev.toLowerCase());
      }
      if (sections.length > 0) {
        sections.forEach((i) => aBookSections.push(i.abbrev.toLowerCase()));
      }
      /*
        DELETE book/sections that don't match the search context
      */
      if (aSearchWords.length === 0 && aBookSections.length > 0) {
        dispatch(storeSearchBook(null));
        dispatch(clearSearchSections());
        removeStrings = makeRemoveString(aBookSections, 0);
      } else {
        if (aBookSections.length > 0) {
          for (let i = 0; i < aBookSections.length; i++) {
            if (i === 0) {
              // book row
              if (aBookSections[i] !== aSearchWords[i]) {
                dispatch(storeSearchBook(null));
                dispatch(clearSearchSections());
                removeStrings = makeRemoveString(aBookSections, 0);
              }
            } else {
              // section rows
              if (i < aSearchWords.length) {
                if (aBookSections[i] !== aSearchWords[i]) {
                  dispatch(deleteSearchSections(aBookSections[i]));
                  removeStrings = makeRemoveString(aBookSections, i);
                }
              } else {
                dispatch(deleteSearchSections(aBookSections[i]));
                removeStrings = makeRemoveString(aBookSections, i);
              }
            }
          }
        }
      }
      /*
        ADD book/sections
      */
      //if (vspace) {
      for (let i = 0; i < aSearchWords.length; i++) {
        let nf = false; // not found
        if (i === 0) {
          // book row
          if (!book && abbrevSpace(aSearchWords[i], v)) {
            const bk = aSearchWords[i];
            const b: iNodeItem | undefined = bookList.data.find(item => {
              return item.abbrev.trim() === bk;
            })
            if (b) {
              dispatch(storeSearchBook(b));
            }
          }
        } else {
          // section rows
          if (i >= aBookSections.length || aBookSections[i] !== aSearchWords[i]) {
            nf = true;
          }
        }
        if (nf && abbrevSpace(aSearchWords[i], v)) {
          const sctn: iNodeItem | undefined = sectionList.data.find((item) => {
            return item.abbrev.trim().toLowerCase() === aSearchWords[i];
          });
          if (sctn) {
            dispatch(storeSearchSections(sctn));
          }
          break;
        }
      }
      //
      let ipos = 0;
      if (removeStrings.length > 0) {
        removeStrings.forEach(abbrev => {
          s = s.replace(abbrev, '');
        })
        for (ipos; ipos < s.length; ipos++) {
          if (s[ipos] !== search[ipos]) {
            break;
          }
        }
      }
      setSearch(s);
      setTwoDots(s.indexOf(" ..") >= 0 ? true : false );
      if (removeStrings.length > 0) {
        setInputPosition(ipos);
      }
      // s.length > 0
    } else {
      // s.length = 0
      setSearch(v);
      setWords("");
      dispatch(clearSearchSections());
      dispatch(storeSearchBook(null));
      setShowPortal(true);
    }
  }

  const abbrevSpace = (brev: string, srchStr: string): boolean => {
    let r = false;
    // if ends w/ space, user meant it; dispatch book/sections
    r = srchStr.indexOf(brev + ' ') >= 0 ? true : false;
    return r;
  }

  /*
      HANDLE CHANGE IN SEARCH FIELD
  */
  // WE CAN DISPATCH DURING ONCHANGE() IN SAME COMPONENT
  // AND CHILDREN CAN READ REDUX VALUE
  //
  const contextProps: iPtypes = {
    referenceElement: referenceElement.current,
    search: search,
    words: words,
    pid: 1,
    handleItemClick
  };

  // search field html property options, e.g., for iphone
  // autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
  // autoFocus
  let context = "";
  if (!book) {
    context = "RSV Bible and CCC Catholic Catechism."
  } else {
    context = book.abbrev;
    sections.forEach( i => {
      context = context + ' ' + i.abbrev;
    })
  }

  return (
    <> {
      version.versionOk ?
        <>
          <Form onSubmit={handleSubmit}>
            <fieldset >
              <legend> Search </legend>
              <div className="search-description">...Bible (rsv) and/or Catholic Catechism (ccc) in one place with clickable cross-references.</div>
              <InputGroup  >
                <Form.Control
                  placeholder="Criteria..."
                  aria-label="Search criteria"
                  aria-describedby="search-button"
                  type="text"
                  value={search}
                  ref={referenceElement}
                  onChange={handleOnChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  spellCheck="false"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                />
                <Button variant="light" id="clear-search" onClick={() => clearSearch()}
                  style={{ borderTopColor: "lightgray", borderBottomColor: "lightgray", borderLeft: "0px" }}
                  title='Clear search field'
                >
                  X
                </Button>
                <Button variant="outline-primary" id="clear-search" onClick={() => displayTips()}
                  style={{ borderTopColor: "lightgray", borderBottomColor: "lightgray", borderLeft: "0px", fontWeight: "bold", fontSize: "larger" }}
                  title='Show search tips'
                >
                  ?
                </Button>
                <Button type="submit" variant="success" id="search-button">
                  Search
                </Button>
                <Button style={{ marginLeft: "0.4rem" }} variant="link" id="stars-list"
                  onClick={() => handleClickStar()}
                >
                  {
                    starsStored.length > 0
                      ? <StarFill className="align-middle" style={{ fontSize: "2rem", color: "gold" }} />
                      : <Star className="align-middle" style={{ fontSize: "2rem", color: "gold" }} />
                  }
                </Button>
              </InputGroup>
            </fieldset>
          </Form>
          {           
            <div className="search-blue">
              <span style={{marginRight: "0.5rem"}}>Context:</span><span>{context}</span>
            </div>
          }

          {showPortal && !showTips && <ListPortal ptypes={contextProps} bookList={bookList} sectionList={sectionList} />}
          {showSearchResults && !twoDots && <SearchResult pid={1} searchString={words} showList={showList} />}
          {showSearchResults && twoDots && <RangeSearch pid={1} searchString={words} showList={showList} />}
          {showStars && <StarsList showList={showList} setShowStars={setShowStars} />}
          {showTips && <Tips />}
        </>
        :
        <VersionReload {...version} />

    }</>
  );
};

export default Search;

