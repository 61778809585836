import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {iStarItem} from '../../dataTypes/dataTypes';

const initialState: iStarItem[] = [];

const storedStarsSlice = createSlice({
  name: 'storedStars',
  initialState,
  reducers: {
    storeStar(state, action: PayloadAction<iStarItem>) {
      return [...state.filter(item => item.abbrev !== action.payload.abbrev), ...[action.payload]]
    }, 
    deleteStar(state, action: PayloadAction<string>) {
      return [...state.filter(item => item.abbrev !== action.payload)]
    },
    clearStars(state) {
      return [...initialState]
    },
    loadStars(state, action: PayloadAction<iStarItem[]>) {
      return [...action.payload]
    }      
  }
})

export const {storeStar, deleteStar, clearStars, loadStars} = storedStarsSlice.actions;
export default storedStarsSlice.reducer;
export const storedStars = (state: RootState) => state.storedStars;

