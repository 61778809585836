import { useState, useEffect } from "react";
import { iStarItem } from "../../dataTypes/dataTypes";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import copy from 'copy-to-clipboard';
import "./search.scss";
import GetFormData from "./GetFormData";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { storedStars, clearStars } from "../search/storedStarsSlice";
import { storePurple, storedPurple } from "../search/purpleSlice";

interface iResultItems {
    data: iStarItem[],
    handleResultItemClick: (i: iStarItem) => void,
    clearAllStars: () => void,
    purple: string[]    
}

interface iResultItem {
    data: iStarItem,
    handleResultItemClick: (i: iStarItem) => void,
    purple: string[]
}


const SearchRow = ({ data, handleResultItemClick, purple }: iResultItem) => {
    return (
        <div>
            <div className="d-flex align-items-center">
                {/*<span style={{ fontSize: "1.2rem" }}>{data.abbrev}</span>
                &nbsp;&nbsp;*/}
                <Button
                    key={Math.random().toString()}
                    variant="link"
                    style={{ fontSize: "1.2rem", textDecoration: "none", color: purple.find( s => s === data.abbrev) ? "purple" : "blue"}}
                    onClick={() => handleResultItemClick(data)}
                >
                    {data.abbrev.length > 0 ? data.abbrev : 'click' }
                </Button>
            </div>
            <div>
                {data.textcontent.replaceAll("<br/><br/>", "  ")}
            </div>
        </div>
    )
}

const DisplayRows = ({ data, handleResultItemClick, clearAllStars, purple }: iResultItems) => {
    //
    const [copyText, setCopyText] = useState(false);
    //
    const copyStarData = () => {
        let copiedText = '';
        copiedText = data.map((item: iStarItem) => (`${item.abbrev}\n${item.textcontent.replaceAll('<br/>','\n')}`)).join('\n\n');
        copiedText = copiedText.length > 0 ? copiedText : 'Nothing was copied.';
        copy(copiedText);
        setCopyText(true);
    }

    return (
        <div className="text-list-container" style={{marginBottom: "5rem"}}>
            <div className = "d-flex align-items-center">
                <span style={{fontSize: "1.4rem", marginRight: "2rem"}}>Stars</span>
                <Button variant="danger" size="sm"
                    onClick={() => clearAllStars()}
                >
                  <Badge bg="secondary" style={{marginRight: "0.5rem"}}>X</Badge>
                  Clear All Stars
                </Button>                

                <Button variant="primary" size="sm" 
                    onClick={() => copyStarData()}
                    style={{marginLeft: "0.5rem"}}
                >
                  {copyText ? <ClipboardCheck style={{marginRight: "0.5rem"}} /> : <Clipboard style={{marginRight: "0.5rem"}} /> }
                  {copyText ? 'Copied!' : 'Copy Star Data'}
                </Button>
                {copyText ? <ClipboardCheck style={{marginLeft: "0.5rem"}} /> : <></> }                
            </div>
            <div>
                {
                    data.map((item: iStarItem) => (
                        <SearchRow key={Math.random().toString()} data={item} 
                        handleResultItemClick={handleResultItemClick} 
                        purple={purple}
                        />
                    ))
                }

            </div>
        </div>
    )
}

const StarsList = (props: { showList: string, setShowStars: (b: boolean) => void}) => {
    
    const [item, setItem] = useState<iStarItem | null>(null);
    const starsStored = useAppSelector(storedStars);
    const purple = useAppSelector(storedPurple);
    const dispatch = useAppDispatch();

    const clearAllStars = () => {
        dispatch(clearStars());
        props.setShowStars(false);
    }   

    useEffect( () => {
        setItem(null);
    }, [props.showList])

    const backToSearchClick = () => {
        setItem(null);
    }

    const handleResultItemClick = (item: iStarItem) => {
        setItem(item);
        dispatch(storePurple(item.abbrev));
    }

    let resultContent = <></>;
    if (item) {
        resultContent = <GetFormData 
            pid = {item.pid}
            docid = {item.docid}
            fromAbbrev = {item.fromAbbrev}
            toAbbrev = {item.toAbbrev}
            fromCid = {item.fromCid}
            toCid = {item.toCid}        
            backToSearchClick = {backToSearchClick}
            heading = {item.abbrev} 
            listType={"stars"}
            />;
    } else {
        resultContent = <DisplayRows data={starsStored} 
            handleResultItemClick={handleResultItemClick} 
            clearAllStars={clearAllStars}
            purple={purple} 
             />;
    }

    return <>{resultContent}</>;

}


export default StarsList;

