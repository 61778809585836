import './app.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap'
import Search from './features/search/Search'
import Help from './features/search/Help'
import About from './features/search/About'
import { Image } from 'react-bootstrap'
import youtube from './images/youtube_icon.png';

function App() {

  return (
    <>
      <Container className="main-container">
        <Navbar collapseOnSelect fixed='top' expand='sm' bg='light' variant='light' >
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav' >
            <Nav >
              <Nav.Link href='/' style={{ marginLeft: "1rem" }}>Search</Nav.Link>
              <Nav.Link href='/Help'style={{ marginLeft: "1rem" }}>Help</Nav.Link>
              <Nav.Link href='/About'style={{ marginLeft: "1rem" }}>About</Nav.Link>
              <Navbar.Brand
                href="https://youtu.be/2huAPCSXpWk"
                target="_blank" rel="noopener noreferrer"
                as="a"
              >
                <Image src={youtube}
                  className="img-fluid rounded"
                  title="5 minute demo"
                  width="32px"
                  style={{ marginLeft: "1rem" }}
                />
              </Navbar.Brand>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <BrowserRouter>
          <Routes>
            <Route path="About" element={<About />} />
            <Route path="Help" element={<Help />} />
            <Route path="Search" element={<Search />} />
            <Route path="/" element={<Search />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </>
  );
}

export default App;

