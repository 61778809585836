import { useState, useEffect } from "react";
import { iGetFormData, iGetFormDataArgs, iXref, iRson } from "../../dataTypes/dataTypes";
import { useGetFormDataQuery } from "../api/apiSlice";
import { storePurple } from "../search/purpleSlice";
import DisplayFormData from "./DisplayFormData";
//
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
    storedStars,
    storeStar,
    deleteStar,
} from "../search/storedStarsSlice";

interface iProps extends iGetFormDataArgs {
    backToSearchClick?: () => void,
    heading?: string,
    listType?: string
}

const GetFormData = ({ pid, docid, fromAbbrev, toAbbrev, fromCid, toCid, backToSearchClick, heading, listType = "none" }: iProps) => {
    //
    const dispatch = useAppDispatch();
    const starsStored = useAppSelector(storedStars);
    //
    const [searchProps, setSearchProps] = useState<iGetFormDataArgs>({ pid: 0, docid: 0, fromAbbrev: "", toAbbrev: "", fromCid: 0, toCid: 0 });
    const [firstProps, setFirstProps] = useState<iGetFormDataArgs>({ pid: 0, docid: 0, fromAbbrev: "", toAbbrev: "", fromCid: 0, toCid: 0 });
    const [stars, setStars] = useState<{ abbrev: string, starProps: iGetFormDataArgs }[]>([]);

    useEffect(() => {
        const p: iGetFormDataArgs = { pid, docid, fromAbbrev, toAbbrev, fromCid, toCid };
        setFirstProps(p);
        setSearchProps(p);
        //
        let abbr = "";
        if (heading) {
            abbr = heading;
        } else {
            abbr = fromAbbrev === toAbbrev ? fromAbbrev : fromAbbrev + " .. " + toAbbrev
        }
        setStars([{ abbrev: abbr, starProps: p }]);
    }, [pid, docid, fromAbbrev, toAbbrev, fromCid, toCid, heading])

    const {
        data = [] as iGetFormData[],
        isSuccess,
        isLoading,
        isError,
        error,
    } = useGetFormDataQuery({
        pid: searchProps.pid,
        docid: searchProps.docid,
        fromAbbrev: searchProps.fromAbbrev,
        toAbbrev: searchProps.toAbbrev,
        fromCid: searchProps.fromCid,
        toCid: searchProps.toCid
    }, { skip: searchProps.pid > 0 ? false : true });


    let searchContent = <></>;

    const handleResultItemClick = (x: iXref) => {
        //
        let newProps: iGetFormDataArgs = { pid: 1, docid: 0, fromAbbrev: "", toAbbrev: "", fromCid: 0, toCid: 0 };
        newProps.docid = x.docid;
        if (x.cid > 0) {
            newProps.fromCid = x.cid
            newProps.toCid = x.cid
        } else {
            const s = x.multi;
            const m: iRson = JSON.parse(s);
            const r = m.multipleIds;
            newProps.fromCid = Number(r.split(" ")[0]);
            newProps.toCid = Number(r.split(" ").pop());
        }
        setSearchProps({ ...newProps });
        dispatch(storePurple(x.abbrev));
    }

    const firstPropsClick = () => {
        setSearchProps(firstProps);
    }

    // to add/remove starred item
    const clickStar = (star: { abbrev: string, starProps: iGetFormDataArgs }, textcontent: string) => {
        if (stars.length > 1 && stars[0].abbrev !== star.abbrev) {
            // local stars and this is not equal to first
            if ([...stars.filter(t => t.abbrev === star.abbrev)].length === 1) {
                // remove star if not first one
                setStars([...stars.filter(t => t.abbrev !== star.abbrev)]);
                // but delete from master store
                dispatch(deleteStar(star.abbrev));
            } else {
                // add star
                setStars([...stars, star]);
                dispatch(storeStar({ abbrev: star.abbrev, ...star.starProps, textcontent }));
            }
        } else {
            // stars length m/b 0 or this is equal to first star
            if (stars[0].abbrev === star.abbrev) {
                // don't add or delete from local stars, but add or delete from stored stars
                if ([...starsStored.filter(t => t.abbrev === star.abbrev)].length === 1) {
                    dispatch(deleteStar(star.abbrev));
                } else {
                    dispatch(storeStar({ abbrev: star.abbrev, ...star.starProps, textcontent }));
                }
            } else {
                // this is not first row of stars, but length
                // add star to both
                setStars([...stars, star]);
                dispatch(storeStar({ abbrev: star.abbrev, ...star.starProps, textcontent }));
            }
        }
    }

    const clickStarred = (starProps: iGetFormDataArgs) => {
        setSearchProps({ ...starProps });
    }

    let firstAbbrev = "";
    if (heading) {
        firstAbbrev = heading;
    } else {
        firstAbbrev = firstProps.fromAbbrev === firstProps.toAbbrev
            ? firstProps.fromAbbrev
            : firstProps.fromAbbrev + " .. " + firstProps.toAbbrev
    }

    if (isSuccess) {
        searchContent = <DisplayFormData
            data={data[0]}
            firstPropsClick={firstPropsClick}
            firstAbbrev={firstAbbrev}
            backToSearchClick={backToSearchClick}
            handleResultItemClick={handleResultItemClick}
            firstProps={firstProps}
            searchProps={searchProps}
            stars={stars}
            clickStar={clickStar}
            clickStarred={clickStarred}
            starProps={searchProps}
            listType={listType}
        />;
    }

    if (isLoading) {
        searchContent = <div>Is loading...</div>;
    }

    if (isError) {
        searchContent = 
            process.env.NODE_ENV !== 'production' 
            ? <div>Error: {JSON.stringify(error)}</div>
            : <div style={{margin: "1rem", fontSize: "1.2rem"}}>Sorry. I had trouble with this range.</div>;
    }

    return <>{searchContent}</>;
}

export default GetFormData;

