import { useState } from "react";
import Portal from "../portal/Portal";
import { usePopper } from "react-popper";
import { iPtypes, iBookSectionList } from "../../dataTypes/dataTypes";
import ListBooks from "./ListBooks";
import ListSections from "./ListSections";
import { useAppSelector } from "../../app/hooks";
import {searchBook} from '../search/searchBookSlice';


const ListPortal = ({ ptypes, bookList, sectionList }: { ptypes: iPtypes, bookList: iBookSectionList, sectionList: iBookSectionList }) => {
  //
  const book = useAppSelector(searchBook);    
  // popper  
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(
    ptypes.referenceElement,
    popperElement,
    {
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        { name: "offset", options: { offset: [5, 26] } },
      ],
      placement: "bottom-start",
    }
  );
  //

  let portalContent = <></>;

  if (book === null) {
    portalContent = <ListBooks ptypes={ptypes} bslist={bookList} />;
  } else {
    portalContent = <ListSections ptypes={ptypes} bslist={sectionList} />;
  }

  return (
    <Portal>
        <div 
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {portalContent}
        </div>
        {/*<div ref={setArrowElement} style={styles.arrow} />*/}
    </Portal>
  );
};

export default ListPortal;
