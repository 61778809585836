import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState: string[] = [];

const purpleSlice = createSlice({
  name: 'purple',
  initialState,
  reducers: {
    storePurple(state, action: PayloadAction<string>) {
      return [...state.filter(s => s !== action.payload), ...[action.payload]]
    } 
  }
})

export const {storePurple} = purpleSlice.actions;
export default purpleSlice.reducer;
export const storedPurple = (state: RootState) => state.purple;

