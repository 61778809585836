import { iXref, iFnote, iGetFormData, iGetFormDataArgs } from "../../dataTypes/dataTypes";
import Button from "react-bootstrap/Button";
import "./formView.scss";
import { StarFill, ArrowUp, ArrowDown, ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import { useAppSelector } from "../../app/hooks";
import { storedStars } from "../search/storedStarsSlice";
import { storedPurple } from "../search/purpleSlice";

interface iStar {
    abbrev: string, starProps: iGetFormDataArgs
}

interface iProps {
    data: iGetFormData,
    firstPropsClick: () => void,
    firstAbbrev: string,
    backToSearchClick?: () => void,
    handleResultItemClick: (x: iXref) => void,
    firstProps: iGetFormDataArgs,
    searchProps: iGetFormDataArgs,
    stars: iStar[],
    clickStar: (star: iStar, textcontent: string) => void,
    clickStarred: (astarProps: iGetFormDataArgs) => void,
    starProps: iGetFormDataArgs,
    listType: string
}

interface iListRefs {
    x: iXref[],
    handleResultItemClick: (item: iXref) => void,
    purple: string[]
}

interface iFoonoteList {
    flist: iFnote[]
}

// list abbrevs for starred items; click switches form to it
const ListStarred = ({ stars, clickStarred }: { stars: iStar[], clickStarred: (starProps: iGetFormDataArgs) => void }) => {
    return (
        <>
            {
                stars.map((j) => {
                    return <span key={j.abbrev}>
                        <Button
                            variant="link"
                            style={{ fontSize: "1rem", textDecoration: "none" }}
                            onClick={() => clickStarred(j.starProps)}
                        >
                            {j.abbrev}
                        </Button>
                    </span>
                })
            }
        </>
    )
}

const ListRefs = ({ x, handleResultItemClick, purple }: iListRefs) => {
    return (
        <>
            {
                x.map((j) => {
                    return <span key={Math.random()}>
                        <Button
                            variant="link"
                            style={{ fontSize: "1rem", textDecoration: "none", color: purple.find( s => s === j.abbrev) ? "purple" : "blue" }}
                            onClick={() => handleResultItemClick(j)}
                        >
                            {j.abbrev}
                        </Button>
                    </span>
                })
            }
        </>
    )
}

const ListFootnotes = ({ flist }: iFoonoteList) => {
    return (
        <div>
            {
                flist.map((f) => (
                    <div key={f.rid}>{f.textcontent}</div>
                ))
            }
        </div>
    )
}


const DisplayFormData = ({ firstPropsClick, firstAbbrev, data,
    backToSearchClick, handleResultItemClick,
    firstProps, searchProps, stars, clickStar, clickStarred, starProps, listType }: iProps) => {

    const stext = data.ctext[0].ctext.replaceAll("-!!-", "<br/><br/>");
    const starsStored = useAppSelector(storedStars);
    const purple = useAppSelector(storedPurple);

    return (
        <div style={{ paddingLeft: "10px" }}>
            {backToSearchClick ?
                <div className="row">
                    <div className="col-12 d-flex align-items-center" style={{ marginBottom: "10px", fontSize: "1rem" }}>
                        <ArrowLeft />&nbsp;&nbsp;
                        <Button
                            variant="link"
                            style={{ textDecoration: "none", padding: "0px" }}
                            onClick={() => backToSearchClick()}
                        >
                            <span>
                                Back to 
                                { listType === 'search'
                                  ? <span style={{fontWeight: "bold"}}> Search </span>
                                  : <span style={{fontWeight: "bold"}}> Stars </span>
                                }
                                 List
                            </span>
                        </Button>
                    </div>
                </div> :
                <></>
            }
            <div className="row">
                <div className="col-12" style={{ marginBottom: "10px" }}>
                    <ListStarred stars={stars} clickStarred={clickStarred} ></ListStarred>
                </div>
            </div>
            <div className="row" style={{ marginBottom: "10px", marginRight: "10px" }}>
                <div className="col-md-9  view-box" style={{ padding: "0px 0px 0px 0px" }}>
                    <div className="d-flex justify-content-between align-items-center"
                        style={{ padding: "10px" }}
                    >
                        <span >{data.docabbrev} - <span style={{ fontWeight: "bold" }}>{data.fabbrev}</span></span>
                        <span >
                            <Button
                                variant="link"
                                style={{ fontSize: "1.2rem", textDecoration: "none", padding: "0px" }}
                                onClick={() => clickStar({ abbrev: data.fabbrev, starProps}, stext)}
                            >
                                <StarFill
                                    style={{
                                        color: starsStored.find(r => r.abbrev === data.fabbrev) ? "gold" : "black"
                                    }}
                                />
                            </Button>
                        </span>
                        <span >
                            <Button
                                variant="link"
                                style={{ textDecoration: "none", padding: "6px" }}
                                onClick={() => handleResultItemClick({ abbrev: '', cid: data.parent, docid: searchProps.docid, multi: '' })}
                                title = "Up 1 level"
                            >
                                <ArrowUp />
                            </Button>
                            {
                                data.childless === 'true'
                                    ?
                                    <ArrowDown />
                                    :
                                    <Button
                                        variant="link"
                                        style={{ textDecoration: "none", padding: "6px" }}
                                        onClick={() => handleResultItemClick({ abbrev: '', cid: searchProps.fromCid, docid: searchProps.docid, multi: '' })}
                                        title = "Show sub-content"
                                        >
                                        <ArrowDown />
                                    </Button>
                            }
                            <Button
                                variant="link"
                                style={{ textDecoration: "none", padding: "6px" }}
                                onClick={() => handleResultItemClick({ abbrev: '', cid: data.nextsib, docid: searchProps.docid, multi: '' })}
                                title="Go to next"
                            >
                                <ArrowRight />
                            </Button>
                            <Button
                                variant="link"
                                style={{ textDecoration: "none", padding: "6px" }}
                                onClick={() => handleResultItemClick({ abbrev: '', cid: data.prevsib, docid: searchProps.docid, multi: '' })}
                                title="Go to previous"
                            >
                                <ArrowLeft />
                            </Button>

                        </span>
                    </div>
                    <div
                        style={{ backgroundColor: "white", padding: "10px" }}
                        dangerouslySetInnerHTML={{ __html: stext }} />
                </div>
                <div className="col-md-3">
                    <ListRefs x={data.xrefs} handleResultItemClick={handleResultItemClick} purple={purple} />
                </div>
            </div>
            <div className="row" style={{ marginBottom: "5rem" }}>
                <div className="col-12" >
                    <ListFootnotes flist={data.fnotes} />
                </div>
            </div>
        </div>
    )
}

export default DisplayFormData;

