import Button from "react-bootstrap/Button";
import "../search/search.scss";
import { iNodeItem, iPtypes, iBookSectionList } from "../../dataTypes/dataTypes";

const ListBooks = ({ptypes, bslist} : {ptypes: iPtypes, bslist: iBookSectionList}) => {
  //
  let portalContent = <></>;
  let srch = ptypes.search; 
  let s = "";
  if (srch.indexOf(' ') > -1) {
    s = srch.substring(0,srch.indexOf(' ')).trim();
  } 

  if (bslist.isSuccess) {
      const filteredList: iNodeItem[] = bslist.data.filter((item) =>
        item.abbrev.startsWith(s)
      );
      if (filteredList.length > 0) {
        portalContent = (
          <>
            {
              <div className="list-box">
                {filteredList.map((item: iNodeItem) => (
                  <Button
                    key={item.cid.toString()}
                    variant="link"
                    style={{ fontSize: "1.2rem", textDecoration: "none" }}
                    onClick={() => ptypes.handleItemClick(item)}
                  >
                    {item.abbrev}
                  </Button>
                ))}
              </div>
            }
          </>
        );
      }
  }

  if (bslist.isLoading) {
    portalContent = <div>Loading books...</div>;
  }

  if (bslist.isError) {
    portalContent = <div>Error: {JSON.stringify(bslist.error)}</div>;
  }

  return <>{portalContent}</>;
};

export default ListBooks;
