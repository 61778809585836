import Button from "react-bootstrap/Button";
import "../search/search.scss";
import { iNodeItem, iPtypes, iBookSectionList } from "../../dataTypes/dataTypes";
import { useAppSelector } from "../../app/hooks";
import {
  searchSections,
} from "../search/searchSectionsSlice";
import { searchBook } from "../search/searchBookSlice";

const ListSections = ({ptypes, bslist} : {ptypes: iPtypes, bslist: iBookSectionList}) => {
  //
  const sections = useAppSelector(searchSections);
  const book = useAppSelector(searchBook);

  let s = ptypes.search.trim();
  let selectedString = book ? book.abbrev : "";
  sections.forEach((i) => {
    selectedString = selectedString + i.abbrev + " ";
  });
 
  let startsWith = "";
  if (s.length > selectedString.length) {
    const rightString = s.substring(selectedString.length);
    if (rightString.indexOf('  ') > -1) {
      startsWith = '';
    } else  if (rightString.indexOf(' ') > -1) {
      startsWith = rightString.substring(0,s.indexOf(' ')).trim();
    } else {
      startsWith = rightString;
    }
  }
  
  s = startsWith;
  
  let portalContent = <></>;

  if (bslist.isSuccess) {
    //
    const filteredList: iNodeItem[] = bslist.data.filter((item) =>
      item.abbrev.toLowerCase().startsWith(s.trim())
    );
    if (filteredList.length > 0) {
      portalContent = (
        <>
          {
            <div className="list-box">
              {filteredList.map((item: iNodeItem) => (
                <Button
                  key={item.cid.toString()}
                  variant="link"
                  style={{ fontSize: "1.2rem", textDecoration: "none" }}
                  onClick={() => ptypes.handleItemClick(item)}
                >
                  {item.abbrev}
                </Button>
              ))}
            </div>
          }
        </>
      );
    }
  }

  if (bslist.isLoading) {
    portalContent = <div>Loading sections...</div>;
  }

  if (bslist.isError) {
    portalContent = <div>Error: {JSON.stringify(bslist.error)}</div>;
  }

  return <>{portalContent}</>;
};

export default ListSections;
