import { useEffect } from "react";
import ReactGA from 'react-ga4';

const About = () => {

  //
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-BTJTDNCFZX');
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);
  //


  return (
    <div className="text-list-container" style={{ marginTop: "1rem", marginBottom: "2rem" }}>
      <h2 style={{ color: "#6495ED", marginBottom: "1rem" }}>About</h2>
      <p>
        Using online sources, this cross-reference was developed by Deacon John Beagan. Please send
        questions or feedback to <a href="mailto:deaconjohnbeagan@gmail.com">deaconjohnbeagan@gmail.com</a>.
      </p>
      <p>
        Also, visit my mission website to help Catholics believe,
        especially those who have fallen away <a href="https://IntentionallyCatholic.com"
          target="_blank" rel="noopener noreferrer"
        >
          Intentionally Catholic .com
        </a>. My short simple book, <span style={{ fontStyle: "italic" }}>Passing the Faith onto Loved Ones</span>, was
        endorsed by Ralph Martin and is free to download.
      </p>
    </div>
  )
}

export default About;