//
const Tip = ({ desc, ex }: { desc: string, ex: string }) => {
    return <div style={{ paddingBottom: "10px" }}>
        <div>{desc}</div>
        <div style={{ fontFamily: "courier new", fontSize: "1.2rem" }} dangerouslySetInnerHTML={{ __html: ex }} />
    </div>
}


const Tips = () => {
    return <div style={{ paddingLeft: "10px" }}>
        <h5 style={{ color: "#6495ED", marginBottom: "1rem" }}>Tips [<span style={{ marginLeft: "3px", marginRight: "3px" }}>?</span>]</h5>
        <div style={{ paddingLeft: "10px", marginRight: "40px", marginBottom: "40px" }}>
            <div style={{ paddingBottom: "10px" }}>
                When the new catechism came out &#40;last one was 500 years ago&#41;,
                the first word I looked up was "antichrist," a popular subject
                in scary movies.
            </div>
            <Tip
                desc={`To search both books, just type in word(s) to look for, e.g., `}
                ex={`antichrist`}
            />
            <div style={{ paddingBottom: "10px", fontWeight: "bold" }}>
                Context:
            </div>
            <div style={{ paddingBottom: "10px" }}>
                Most of the content uses abbreviations to set search context and specify range. For example,
                rsv for bible and ccc for catechism; also, bible sections, books, chapters and verses.
                But just paragraph numbers in the catechism. Click into the search field, then rsv and its
                subsections to explore. Click the question mark ? to return to Tips.
            </div>
            <Tip
                desc={`Limit search to the bible. Type or click "rsv"`}
                ex={`rsv antichrist`}
            />
            <Tip
                desc={`Limit to New Testament. Type or click "rsv nt"`}
                ex={`rsv nt antichrist`}
            />
            <Tip
                desc={`Limit search to catechism. Type or click "ccc"`}
                ex={`ccc antichrist`}
            />
            <div style={{ paddingBottom: "10px", fontWeight: "bold" }}>
                Specify a Range:
            </div>
            <Tip
                desc={`e.g., the Beatitudes. Notice the dots; no colons`}
                ex={`rsv nt mt.5.1 .. mt.5.11`}
            />
            <Tip
                desc={`Or one verse, e.g., John 3:16 -- end with two dots`}
                ex={`rsv nt jn.3.16 ..`}
            />
            <Tip
                desc={`Or a catechism paragraph`}
                ex={`ccc 133 ..`}
            />
            <div style={{ paddingBottom: "10px", fontWeight: "bold" }}>
                Complex Search:
            </div>
            <div style={{ paddingBottom: "10px" }}>
                Space between search words indicates OR. An ampersand sign &amp; indicates AND.
            </div>
            <Tip
                desc={`Search New Testament for devil OR satan`}
                ex={`rsv nt devil satan`}
            />
            <Tip
                desc={`AND`}
                ex={`rsv nt devil & satan`}
            />
            <Tip
                desc={`Look for an exact phrase. Use quotes.`}
                ex={`"evil one"`}
            />
            <Tip
                desc={`Use parentheses: devil AND satan, OR "evil one"`}
                ex={`rsv nt (devil & satan) "evil one"`}
            />
            <Tip
                desc={`Use exclamation mark ! for not, e.g., does not include murder`}
                ex={`rsv nt ((devil & satan) "evil one") & !murder`}
            />
            <div>
                Please send
                questions or feedback to <a href="mailto:deaconjohnbeagan@gmail.com">deaconjohnbeagan@gmail.com</a>.
            </div>
        </div>
    </div>
}

export default Tips;


